import React, {
  useEffect,
  useState,
  forwardRef,
  useImperativeHandle,
} from 'react';

const Cursor = forwardRef((props, ref) => {
  const [mousePosition, setMousePosition] = useState({ x: -10, y: -10 });
  const [hideCursor, setHideCursor] = useState(false);
  const [cursorVariant, setCursorVariant] = useState('default');
  const { location } = props;

  useEffect(() => {
    const mouseMove = (e) => {
      setMousePosition({
        x: e.clientX,
        y: e.clientY,
      });
    };

    const touchMove = () => {
      setHideCursor(true);
    };

    setCursorVariant('default');

    window.addEventListener('mousemove', mouseMove);
    window.addEventListener('touchmove', touchMove);
    return () => {
      window.removeEventListener('mousemove', mouseMove);
      window.removeEventListener('touchmove', touchMove);
    };
  }, [location]);

  useImperativeHandle(ref, () => (
    {
      setCursor(state) {
        setCursorVariant(state);
      },
    }
  ));

  function renderCursorStyle() {
    let offset = 8;
    if (cursorVariant === 'large') {
      offset = 16;
    }

    return {
      left: mousePosition.x - offset,
      top: mousePosition.y - offset,
      display: hideCursor ? 'none' : 'block',
    };
  }

  return <div className={cursorVariant === 'large' ? 'cursor--large' : 'cursor'} style={renderCursorStyle()} />;
});

export default Cursor;
