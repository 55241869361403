import React from 'react';
import HtmlParser from 'html-react-parser';
import { Link, useLoaderData, useOutletContext } from 'react-router-dom';
import { motion } from 'framer-motion';
import { getProjectDetails } from '../../data/projectService';
import ScrollToTop from '../components/scroller';
import HomeFooter from '../components/footer';
import '../../styles/style.scss';

import imgArrowPrev from '../../assets/images/arrow-prev.svg';
import imgArrowNext from '../../assets/images/arrow-next.svg';

export async function ProjectLoader({ params }) {
  const projectDetails = await getProjectDetails(params.projectId);
  return { projectDetails };
}

export function Project() {
  const { projectDetails } = useLoaderData();
  const setCursorMode = useOutletContext();

  return (
    <div>
      <ScrollToTop />
      <header className="navigation full-screen-wrapper">
        <div className="nav-wrapper">
          <div className="navigation__block">
            <Link className="navigation__home-link" target="_self" to="/">k.kharlouskaya</Link>
            <div className="navigation__pages-links">
              { projectDetails.prevRef
                && (
                <Link onMouseEnter={() => { setCursorMode('large'); }} onMouseLeave={() => { setCursorMode('default'); }} className="navigation__pages-link navigation__link-prev" to={projectDetails.prevRef}>
                  <img className="navigation__link-icon navigation__icon-prev" loading="lazy" src={imgArrowPrev} alt="" />
                  <span data-txt="prev" className="navigation__link-name navigation__link-name-prev">prev</span>
                </Link>
                )}
              { projectDetails.nextRef
                && (
                <Link onMouseEnter={() => { setCursorMode('large'); }} onMouseLeave={() => { setCursorMode('default'); }} className="navigation__pages-link navigation__link-next" to={projectDetails.nextRef}>
                  <span data-txt="next" className="navigation__link-name navigation__link-name-next">next</span>
                  <img className="navigation__link-icon navigation__icon-next" loading="lazy" src={imgArrowNext} alt="" />
                </Link>
                )}
            </div>
          </div>
        </div>
      </header>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 0.5 }}
      >
        <section className="project-hero full-screen-wrapper">
          <div className="content-wrapper">
            <div className="project-hero__block">
              <div className="project-hero__title">
                { projectDetails.title }
              </div>
              <div className="project-hero__content">
                <div className="project-hero__descr">
                  { HtmlParser(projectDetails.description)}
                </div>
                <div className="project-hero__responsibilities">
                  <div className="project-hero__responsibilities-title">
                    Responsibilities:
                  </div>
                  <div className="project-hero__responsibilities-descr">
                    { HtmlParser(projectDetails.responsibilities)}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="project-result full-screen-wrapper">
          <div className="content-wrapper">
            <img className="project-result__img" src={projectDetails.img} alt="" />
          </div>
        </section>
        <div className="full-screen-wrapper">
          <HomeFooter className="footer-wrapper--project" />
        </div>
      </motion.div>
    </div>
  );
}
