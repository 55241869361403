/* eslint-disable */
import React from 'react';
import { Link, useLoaderData, useOutletContext } from 'react-router-dom';
import { motion } from 'framer-motion';
import { getProjects } from '../../data/projectService';
import HomeHeader from './header';
import HomeFooter from '../components/footer';
import '../../styles/style.scss';
import ScrollToTop from '../components/scroller';

export async function HomeLoader() {
  const projects = await getProjects();
  return { projects };
}

export function Home() {
  const { projects } = useLoaderData();
  const setCursorMode = useOutletContext();

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.5 }}
    >
      <ScrollToTop />

      <HomeHeader />

      <section className="works">
        <div className="works-wrapper">
          <ul className="works__list">
            {
              projects.map((project) => (
                <li onMouseEnter={() => { setCursorMode('large') }} onMouseLeave={() => { setCursorMode('default') }} key={project.id} className={'works__item works__item--'.concat(project.order)}>
                  <Link className="works__item-link" target={project.refTarget} to={project.ref} rel="noreferrer" preventScrollReset="false">
                    <div className="works__item-content">
                      <div className="works__item-media">

                        { project.img
                        && (
                          <img
                            className="works__item-img"
                            src={project.img}
                            alt=""
                          />
                        )}

                        { project.placeholderText
                        && (
                          <div className="works__item-placeholder">
                            <span className="works__item-placeholder-name">{project.placeholderText}</span>
                          </div>
                        )}

                      </div>
                      <div className="works__item-description">
                        <span className="works__item-subtitle">
                          {project.subtitle}
                        </span>
                        <span className="works__item-title">
                          {project.title}
                        </span>
                      </div>
                    </div>
                  </Link>
                </li>
              ))
            }

          </ul>
        </div>
      </section>

      <HomeFooter className="footer-wrapper--home"/>

    </motion.div>
  );
}
