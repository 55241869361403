import React from 'react';
import '../../styles/style.scss';

import imgArt from '../../assets/images/ART.svg';
import imgDes from '../../assets/images/DES.svg';
import imgDev from '../../assets/images/DEV.svg';

function BgAnimation() {
  const boxesIndexes = [1, 2, 3];

  return (
    <div>
      <div className="bg">
        {
          boxesIndexes.map((index) => (
            <div key={index} className={'bg__box bg__box-'.concat(index)}>
              <div className="bg__img-dev">
                <picture>
                  <img loading="lazy" src={imgDev} alt="" />
                </picture>
              </div>
              <div className="bg__img-art">
                <picture>
                  <img loading="lazy" src={imgArt} alt="" />
                </picture>
              </div>
              <div className="bg__img-des">
                <picture>
                  <img loading="lazy" src={imgDes} alt="" />
                </picture>
              </div>
            </div>
          ))
        }
      </div>

      <div className="bg-line">
        <svg
          preserveAspectRatio="none"
          className="bg-line__img"
          width="840"
          height="349"
          viewBox="0 0 840 349"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect width="1" height="349" fill="#F7F7F8" />
          <rect x="19" width="1" height="349" fill="#F7F7F8" />
          <rect x="270" width="1" height="349" fill="#F7F7F8" />
          <rect x="289" width="1" height="349" fill="#F7F7F8" />
          <rect x="820" width="1" height="349" fill="#F7F7F8" />
          <rect x="839" width="1" height="349" fill="#F7F7F8" />
          <rect x="550" width="1" height="349" fill="#F7F7F8" />
          <rect x="569" width="1" height="349" fill="#F7F7F8" />
        </svg>
      </div>
    </div>
  );
}

export default BgAnimation;
