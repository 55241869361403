import { AnimatePresence } from 'framer-motion';
import React, { useRef } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import Cursor from './components/cursor';

export default function Root() {
  const location = useLocation();
  const cursor = useRef(null);

  return (
    <>
      <Cursor location={location} ref={cursor} />
      <AnimatePresence location={location} key={location.key} mode="wait">
        <Outlet context={(state) => cursor.current.setCursor(state)} />
      </AnimatePresence>
    </>
  );
}
