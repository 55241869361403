import React from 'react';
import { useOutletContext } from 'react-router-dom';
import '../../styles/style.scss';
import BgAnimation from './bg_animation';

function HomeHeader() {
  const setCursorMode = useOutletContext();

  return (
    <section className="main" id="main">
      <div className="wrapper">
        <div className="main__header">
          <div className="main__logo-block">
            <a className="main__logo" href="/">k.kharlouskaya</a>
          </div>
          <div className="main__social">
            <ul className="main__social-block">
              <li onMouseEnter={() => { setCursorMode('large'); }} onMouseLeave={() => { setCursorMode('default'); }} className="main__social-item">
                <a
                  className="main__social-link"
                  href="https://dribbble.com/uxkriss"
                  target="_blank"
                  rel="noreferrer"
                >
                  <span data-txt="Dribble" className="main__link-txt">Dribble</span>
                </a
              >
              </li>
              <li onMouseEnter={() => { setCursorMode('large'); }} onMouseLeave={() => { setCursorMode('default'); }} className="main__social-item">
                <a
                  className="main__social-link"
                  href="https://www.linkedin.com/in/krystsina-kharlouskaya-3b0699132"
                  target="_blank"
                  rel="noreferrer"
                >
                  <span data-txt="LinkedIn" className="main__link-txt">LinkedIn</span>
                </a
              >
              </li>
            </ul>
          </div>
        </div>
        <div className="main__content">
          <div className="main__title-content">
            <h1 className="main__title">
              Hi
              <br />
              I’m Kriss
              {' '}
              <br />
            </h1>
          </div>
          <div className="main__descr">
            I&rsquo;m a&nbsp;developer and designer working on the Web Design Team at&nbsp;
            <a
              className="main__bank-link"
              target="_blank"
              href="https://www.tinkoff.ru/"
              rel="noreferrer"
            >
              Tinkoff
              <nobr> Bank</nobr>
            </a
          >
            .&nbsp;
            I&rsquo;m passionate about creating interfaces for systems that have no&nbsp;analogues
          </div>
        </div>
      </div>

      <BgAnimation />

    </section>
  );
}

export default HomeHeader;
