import React from 'react';
import {
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom';

/* eslint-disable react/jsx-props-no-spreading */
export default function withRouter(Component) {
  function ComponentWithRouterProp(props) {
    const location = useLocation();
    const navigate = useNavigate();
    const params = useParams();
    return (
      <Component
        {...props}
        router={{ pathname: location, navigate, params }}
      />
    );
  }

  return ComponentWithRouterProp;
}
