import { useEffect } from 'react';
import withRouter from './withRouter';

function ScrollToTop({ children, pathname }) {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'instant',
    });
  }, [pathname]);

  return children || null;
}

export default withRouter(ScrollToTop);
