import React from 'react';
import { Link } from 'react-router-dom';
import '../../styles/style.scss';

function HomeFooter({ className }) {
  const socialLinks = [
    {
      label: 'Dribble',
      href: 'https://dribbble.com/uxkriss',
    },
    {
      label: 'LinkedIn',
      href: 'https://www.linkedin.com/in/krystsina-kharlouskaya-3b0699132',
    },
    {
      label: 'CV',
      href: 'https://read.cv/kr.kharlouskaya',
    },
  ];

  return (
    <section className={className}>
      <div className="footer__block">
        <h2 className="footer__title">Jump to</h2>
        <div className="footer__social">
          <ul className="footer__social-block">
            {
              socialLinks.map((link) => (
                <li key={link.label} className="footer__social-item">
                  <Link
                    to={link.href}
                    target="_blank"
                    className="footer__social-link"
                    rel="noreferrer"
                  >
                    {link.label}
                  </Link>
                </li>
              ))
            }
          </ul>
        </div>
        <div className="footer__copyright-block">
          <p className="footer__copyright">© Krystsina Kharlouskaya 2023</p>
        </div>
      </div>
    </section>
  );
}

export default HomeFooter;
