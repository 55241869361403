import img1 from '../assets/images/1.png';
import img2 from '../assets/images/2.png';
import img4 from '../assets/images/4.png';
import img5 from '../assets/images/5.png';
import img6 from '../assets/images/6.png';
import img7 from '../assets/images/7.png';

const imgsMap = {
  imgId1: img1,
  imgId2: img2,
  imgId4: img4,
  imgId5: img5,
  imgId6: img6,
  imgId7: img7,
  default: 'blank',
};

async function getProjectsDtos() {
  return [
    {
      id: 'des_dev',
      order: 1,
      hasDetails: false,
      ref: 'https://gitlab.com/uxkriss/portfolio2.0',
      title: 'Twenty-wenty Portfolio',
      subtitle: 'Design & Developement',
      placeholderText: 'Twenty-wenty portfolio',
    },
    {
      id: 'ui_ux_des_cp',
      order: 2,
      hasDetails: true,
      title: 'Client services',
      subtitle: 'UI/UX Design – CoinsPaid',
      description: 'As the lead designer of the client systems stream for cryptocurrency processing, I focus on enhancing and evolving applications that assist our clients in seamlessly receiving and managing funds in cryptocurrency. <br/><br/> Achievements: <ul><li>Implemented a new mass payout functionality, significantly reducing the time required for activities such as disbursing employee salaries. This boosted company profits and prevented potential loss of leads </li><li>Developed a fresh invoicing concept to enhance conversion rates, lower support expenses, expand into new markets, and draw in new merchants </li><li>Redesigned the merchant’s personal account interface by incorporating insights from user sessions and support feedback. Expanded the design system by introducing new components </li><li>Created a comprehensive knowledge base outlining the fundamentals of interface copywriting principles </li></ul>',
      responsibilities: 'Research, UX Design, UI Design, UX-Writing, Project Strategy, Development support',
      img: 'imgId6',
    },
    {
      id: 'tin_bn',
      order: 3,
      hasDetails: false,
      ref: 'https://www.tinkoff.ru/',
      title: 'How We Worked?',
      subtitle: 'Tinkoff Bank',
      placeholderText: 'Tinkoff Bank',
    },
    {
      id: 'ui_ux_des_tin',
      order: 4,
      hasDetails: true,
      title: 'ATM SSDrive',
      subtitle: 'UI/UX Design – Tinkoff',
      description: 'ATM SSDrive is an internal bank system for managing and monitoring its own ATM network. It consists of various modules for ATM management like real time ATM Monitoring, Cash Management, Cleaning Management, Admin Management, Issue & SLA Management, etc. It allows various roles to perform daily tasks: monitor the status of ATMs, update and install software, run tasks, manage the ATM network, configure security keys and so on. <br/><br/> The main challenge in designing internal systems is a development resources limit, time pressure and narrow audience which might interfere with creation of convenient and scalable solutions. From the other side it usually gives ability to easily interact with end-users and quickly collect data on the process of their work. As a result of the project, we\'ve successfully launched MVP versions of several application modules (administration, monitoring, service requests, cleaning) taking in account user feedback.',
      responsibilities: 'Research, UX Design, UI Design, UX-Writing, Project Strategy, Development support',
      img: 'imgId2',
    },
    {
      id: 'pr_des_tin_2',
      order: 5,
      hasDetails: true,
      title: 'Payment Form',
      subtitle: 'Product Design – Tinkoff',
      description: 'Tinkoff Kassa – one of the strategically valuable areas of the bank which helps legal entities accept payments on the website, in the mobile application and social networks. <br/><br/> Working as a designer in the Tinkoff Kassa department, I\'ve helped to improve, test and successfully launch a new payment form. The initial form had its drawbacks that lead to the need in a new one focused on convenience, simplicity and performance. <br/><br/> After the launch: <ul><li> the form loading time was significantly reduced</li><li>the payment process was simplified by displaying major payment methods on the main screen</li><li>the style was aligned with the bank wide design system</li><li>the number of support calls was significantly reduced through texts elaboration and copywriting</li><li>the number of Tinkoff Pay active users was increased</li></ul>',
      responsibilities: 'Research, UX Design, UI Design, UX-Writing, Project Strategy, Development support',
      img: 'imgId5',
    },
    {
      id: 'nk',
      order: 6,
      hasDetails: false,
      ref: 'https://www.netcracker.com',
      title: 'How We Worked?',
      subtitle: 'Netcracker',
      placeholderText: 'Netcracker',
    },
    {
      id: 'ui_ux_des_nk_2',
      order: 7,
      hasDetails: true,
      title: 'Order Management',
      subtitle: 'UI/UX Design – Netcracker',
      description: 'Order Management is an enterprise web system for orders provisioning. It provides services for the system administrator for quick search on telecom customers’ orders, various tasks management and effective support. <br/><br/> Catalog Service is another enterprise web system which allows the catalog configurator to create actual telecom products. The above two system closely interact with each other and serve the goal to provide telecom services on all stages (from purchase till installation and setup).',
      responsibilities: 'Research, UX Design, UI Design, UX-Writing, Design System Creation',
      img: 'imgId4',
    },
    {
      id: 'pr_des_tin',
      order: 8,
      hasDetails: true,
      title: 'MMS',
      subtitle: 'Product Design – Tinkoff',
      description: 'The merchant management system is one of the projects of the "Tinkoff Kassa" department. It allows its users (account managers of the bank) to manage merchant accounts, customise tariffs and limits for payments and modify acquiring services. It provides the full cycle of service starting from activation requests management till the final provisioning and customer support. <br/><br/> My Team conducted in-depth interviews with account managers, formulated all the problems that users faced when working in the current legacy system. As a result we\'ve implemented a more scalable solution utilising modern technologies and aligned with the bank wide design system.',
      responsibilities: 'Research, UX Design, UI Design, UX-Writing, Project Strategy, Development support',
      img: 'imgId1',
    },
    {
      id: 'ui_ux_des_nk_3',
      order: 9,
      hasDetails: true,
      title: 'Order Entry',
      subtitle: 'UI/UX Design – Netcracker',
      description: 'Order Entry is enterprise cloud system for CSRs (Customer Service Representatives) of telecom operator. The main goal of this system is to help Call Center employee to assist a physical person in making telecom purchases quickly and productively.  <br/><br/> Working closely with BAs we\'ve composed universal application focused on the key user scenarios. The major challenge on this particular system was a need to support two display modes: a standalone web app and an imbedded UI into third-party call center interface.  <br/> We\'ve focused on a convenience to work daily with the system: it had to carefully manage the attention of the operator, not distract him over nonsense and not allow costly mistakes to be made.  <br/> Apart from major tasks the project had become a base for the new design system of the company. It later evolved into the system serving as a guide for various company system domains: graphical and engineering interfaces, e-commerce systems, back office…  <br/><br/> This system is currently in production and is successfully used by call center employees of several large telecom providers in USA, Canada and South America.',
      responsibilities: 'Research, UX Design, UI Design, UX-Writing, Design System Creation',
      img: 'imgId7',
    },
  ].sort((el) => el.order);
}

export async function getProjects() {
  const dtos = await getProjectsDtos();

  return dtos.map((dto) => (
    {
      id: dto.id,
      order: dto.order,
      title: dto.title,
      subtitle: dto.subtitle,
      img: dto.img ? imgsMap[dto.img] : null,
      placeholderText: dto.placeholderText,
      ref: dto.hasDetails ? `/project/${dto.id}` : dto.ref,
      refTarget: dto.hasDetails ? '_self' : '_blank',
    }
  )).sort((vm) => vm.order);
}

export async function getProjectDetails(id) {
  const dtos = await getProjectsDtos();
  const dtosWithProjDetails = dtos.filter((dto) => dto.hasDetails).sort((dto) => dto.order);

  const result = dtosWithProjDetails.find((dto) => dto.id === id);

  if (!result) {
    throw Error('Project not found');
  }

  let prevId = null;
  let nextId = null;

  if (dtosWithProjDetails.length > 1) {
    const currentIndex = dtosWithProjDetails.indexOf(result);

    if (currentIndex === 0) {
      prevId = dtosWithProjDetails[dtosWithProjDetails.length - 1].id;
    } else {
      prevId = dtosWithProjDetails[currentIndex - 1].id;
    }

    if (currentIndex === dtosWithProjDetails.length - 1) {
      nextId = dtosWithProjDetails[0].id;
    } else {
      nextId = dtosWithProjDetails[currentIndex + 1].id;
    }
  }

  return {
    id: result.id,
    title: result.title,
    subtitle: result.subtitle,
    description: result.description,
    responsibilities: result.responsibilities,
    img: imgsMap[result.img] ?? imgsMap.default,
    prevRef: prevId ? `/project/${prevId}` : null,
    nextRef: nextId ? `/project/${nextId}` : null,
  };
}
